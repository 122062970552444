<script lang="ts" setup>
  import { Text } from '@bt/design-system'
  import renderRichText from '@/utils/renderSbRichText'

  const { blok } = defineProps<{
    blok: any
  }>()

  const content = computed(() => renderRichText(blok.text))
</script>

<template>
  <div class="heading-description-wrapper">
    <Text
      v-if="blok.heading"
      :tag="blok.headingStyle"
      :text="blok.heading"
      :type="blok.headingStyle"
      class="heading"
    />
    <Text
      :text="content"
      tag="div"
      type="body1"
    />
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .heading-description-wrapper {
    .heading {
      margin-bottom: rem(16);

      @include desktop-up {
        margin-bottom: rem(24);
      }
    }
  }
</style>
